import React from "react"
import { graphql } from "gatsby"
import GridLayout from "../Layout/GridLayout"
import { useI18next } from "../i18n"

import { Seo } from "../seo"
import { DynamicRow } from "../rows/DynamicRow"

export default (props) => {
  const { t } = useI18next()
  const page = props.data.wordPress.page

  const isLanding = page.tags?.nodes?.reduce((prev, curr) => {
    return prev || curr.name == "Landing"
  }, false)
  // console.log("Page data: ", page)
  // console.log("Is Landing: ", isLanding)
  // console.log("Context: ", props.pageContext)
  // console.log("Props: ", props)
  // console.log("Articles: ", pressArticles)

  return (
    <GridLayout className={`color-active-${page.details.coloring}`} isLanding={isLanding}>
      <Seo title={page.seo.title} description={page.seo.metaDesc} noindex={isLanding} />

      {page.details.rows?.map((row, i) => (
        <DynamicRow data={row} graphQLData={props.data} key={i} />
      ))}
    </GridLayout>
  )
}

export const query = graphql`
  query ($id: ID!, $tags: [String!], $buyingGroupsWpIds: [ID!], $languageCode: Wordpress_LanguageCodeFilterEnum!, $relatedPostsLimit: Int) {
    appsync {
      ...dbBuyingGroupsData
    }
    wordPress {
      page(id: $id) {
        title
        slug
        uri
        language {
          slug
        }
        seo {
          title
          metaDesc
        }
        translations {
          slug
          language {
            slug
          }
        }
        tags {
          nodes {
            slug
            name
          }
        }
        details {
          uid
          coloring
          rows {
            ...pagesRow
          }
        }
      }
      ...pageAccessoryData
    }
  }
`
